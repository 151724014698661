import { PassedInitialConfig } from 'angular-auth-oidc-client';
import { environment } from '../../environments/environment';

export const authConfig: PassedInitialConfig = {
  config: {
            authority: environment.authority,
            redirectUrl: window.location.origin,
            postLogoutRedirectUri: window.location.origin,
            clientId: 'bpp-public-client',
            scope: 'openid profile',
            responseType: 'code',
            silentRenew: false,
            secureRoutes: ['https://api-staging.datasync-mcd.ch', 'https://api.datasync-mcd.ch']
        }
}
