<mcdo-booking-form 
    [stores]="stores"
    [storeTooltipExtendedTemplate]="storeTooltipTemplate"
    (onSlotSelected)="onSlotSelected($event)"
    (onStoreSelected)="onStoreSelected($event)"
    [secondStepCompleteSubject]="onBirthdayEventValidate"
    [secondStepLabelTemplate]="secondStepLabel"
    [contactAddressRequired]="birtdayPartyEvent.cards?.requested"
    (onContactSet)="birtdayPartyEvent.contact = $event"
    [eventRecapTemplate]="recapTemplate"
    (onConfirm)="onBookingConfirmed()"
    [isConfirmed]="isConfirmed"
    confirmationImage="/assets/images/birthday_planner_child.webp">

    @if(currentSlot) {
        <app-birthday-party-booking [slot]="currentSlot" [event]="birtdayPartyEvent" [store]="currentStore" (onValidate)="onPartyValidate($event)"></app-birthday-party-booking>
    }
    
</mcdo-booking-form>

<ng-template #secondStepLabel> 
    <i class="bi bi-cake2-fill h1 text-primary"></i>
</ng-template>

<ng-template #storeTooltipTemplate let-params>
    @if(params.slots) {
        <div>
            <i class="bi bi-person-fill"></i>
            {{ 'booking-form.store-slot-select.numChilds' | translate }} : {{ params.slots.childrenCountMax }}
        </div>
        <div>
            <i class="bi bi-cake2-fill"></i>
            {{ 'booking-form.store-slot-select.ageChild' | translate }} : {{ params.slots.childAgeMin }} - {{ params.slots.childAgeMax }}
        </div>
    } @else {
        {{ "booking-form.store-slot-select.noSlotsAvailable" | translate }}
    }
</ng-template>

<ng-template #recapTemplate>
    @if(eventComplete && birtdayPartyEvent.contact && currentStore) {
        <app-birthday-party-recap [event]="birtdayPartyEvent" [store]="currentStore"></app-birthday-party-recap>
    }
</ng-template>