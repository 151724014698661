import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { PriceSettings, Store, StoreSettingsService } from 'common';
import { BirthdayPartyEvent } from '../../../data/birthday-party-event';
import { BirthdayPartySettings } from '../../../data/birthday-party-settings';

@Component({
  selector: 'app-birthday-party-recap',
  standalone: true,
  imports: [TranslateModule, CommonModule],
  templateUrl: './birthday-party-recap.component.html',
  styleUrl: './birthday-party-recap.component.scss'
})
export class BirthdayPartyRecapComponent implements OnInit {

  @Input({ required: true })
  event: BirthdayPartyEvent;

  @Input({ required: true })
  store: Store;

  globalPrice: PriceSettings;
  
  private _price: number;

  get price() {
    return this._price || (this.event.onlineReduction ? this.globalPrice?.online : this.globalPrice?.other);
  }

  private _extraChildPrice: number;

  get extraChildPrice() {
    return this._extraChildPrice || this.globalPrice?.extraChild;
  }

  constructor(
    private storeSettingsSvc: StoreSettingsService<BirthdayPartySettings>
  ) {}

  ngOnInit(): void {
    this.storeSettingsSvc.getGlobalPriceSettings().subscribe(
      x => this.globalPrice = x
    );
    this.storeSettingsSvc.getSettings(this.store.storeIdNumber).subscribe(
      x => {
        this._extraChildPrice = x.additionalChildPrice;
        this._price = x.price;
      });
  }

  computePrice() {
    let extraChildPricing = 0;
    let extraChildrenCount = this.event.childrenCount - 8;

    if (extraChildrenCount > 0) {
      extraChildPricing = extraChildrenCount * this.extraChildPrice;
    }

    return this.price + extraChildPricing;
  }

}
