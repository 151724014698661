<div class="theme-container">
    @for (theme of availableThemes; track $index) {
        <div class="theme" (click)="currentTheme = theme">
            <div>
                <div class="theme-overlay" [class.selected-overlay]="currentTheme === theme">
                    <i class="bi bi-check-circle" aria-hidden="true"></i>
                </div>
                <img [src]="'assets/themes/' + theme + '_party.jpg'" />
            </div>
            <div
                style="text-align: center;"
                [style.font-weight]="currentTheme === theme ? 'bold' : 'unset'"
            >
                {{ 'global.theme.' + theme | translate }}
            </div>
        </div>
    }
</div>