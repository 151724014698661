import { Component, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-theme-selector',
  standalone: true,
  imports: [TranslateModule],
  templateUrl: './theme-selector.component.html',
  styleUrl: './theme-selector.component.scss',
  providers: [
    { provide: NG_VALUE_ACCESSOR, multi: true, useExisting: forwardRef(() => ThemeSelectorComponent) }
  ]
})
export class ThemeSelectorComponent implements ControlValueAccessor{

  private _currentTheme: string;

  get currentTheme() {
    return this._currentTheme;
  }

  set currentTheme(theme: string) {
    this._currentTheme = theme;
    this.onTouched();
    this.onChange(theme);
  }

  availableThemes: string[] = ['fairy', 'circus', 'superhero'];

  private onChange = (_: string) => {};
  private onTouched = () => {};

  writeValue(theme: string): void {
    this.currentTheme = theme;
  }

  registerOnChange(fn: (theme: string) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    
  }
}
