import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { EventService, McdoBookingFormComponent, Store, StoreService } from 'common';
import { Subject } from 'rxjs';
import { BirthdayPartyAvailableSlot } from '../../data/birthday-party-available-slot';
import { BirthdayPartyEvent } from '../../data/birthday-party-event';
import { BirthdayPartyRecapComponent } from '../common/birthday-party-recap/birthday-party-recap.component';
import { BirthdayPartyBookingComponent } from './birthday-party-booking/birthday-party-booking.component';

@Component({
  selector: 'app-booking-form',
  standalone: true,
  imports: [McdoBookingFormComponent, BirthdayPartyBookingComponent, CommonModule, BirthdayPartyRecapComponent, TranslateModule],
  templateUrl: './booking-form.component.html',
  styleUrl: './booking-form.component.scss'
})
export class BookingFormComponent implements OnInit {

  onBirthdayEventValidate = new Subject<boolean>();

  birtdayPartyEvent = new BirthdayPartyEvent();
  stores: Store[];

  currentSlot: BirthdayPartyAvailableSlot;
  currentStore: Store;
  nextAvailableDates: Date[];
  eventComplete: boolean;
  isConfirmed: boolean;

  constructor(
    private storeSvc: StoreService,
    private eventSvc: EventService<BirthdayPartyEvent>,
    private oidcSvc: OidcSecurityService
  ) { }

  ngOnInit(): void {
    this.storeSvc.getAll({birthdayParties: true}).subscribe(stores => this.stores = stores.filter(x => x.matchesCriteria));
  }

  onSlotSelected(slot: BirthdayPartyAvailableSlot) {
    this.currentSlot = slot;
    if (slot) {
      this.birtdayPartyEvent.date = slot.timeSlotsStart[0];
      this.birtdayPartyEvent.durationMinutes = slot.durationMinutes;
    }
  }

  onStoreSelected(store: Store) {
    this.currentStore = store;
    this.birtdayPartyEvent.storeIdentifier = store?.storeIdNumber;
  }
  
  onBookingConfirmed() {
    this.oidcSvc.getUserData().subscribe(userData => {
      if (userData) {
        this.birtdayPartyEvent.role = userData.roles[0];
        this.birtdayPartyEvent.onlineReduction = false;
      } else {
        this.birtdayPartyEvent.onlineReduction = true;
      }
      
      this.eventSvc.saveParty(this.birtdayPartyEvent).subscribe(() => this.isConfirmed = true);
    });
  }

  onPartyValidate(isValid: boolean) {
    this.eventComplete = isValid;
    this.onBirthdayEventValidate.next(isValid);
  }    
}
