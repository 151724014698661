import { McDonaldsEvent } from 'common';
import { Cards } from './cards';
import { Child } from './child';

export class BirthdayPartyEvent extends McDonaldsEvent {
    theme: string;
    childrenCount: number;
    cakeType: string;
    cards: Cards;
    child: Child;
    onlineReduction: boolean;
}
