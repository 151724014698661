<div class="row border rounded p-3">
    <div class="col-md-6">
        <div class="row">
            <div class="col-4">
                <label class="form-label"> {{ 'recap.label.restaurant' | translate }}</label>
            </div>
            <div class="col-8">
                {{ store.publicName }}
            </div>
        </div>
        <div class="row">
            <div class="col-4">
                <label class="form-label">{{ 'recap.label.date' | translate }}</label>
            </div>
            <div class="col-8">
                {{ event.date | date:'dd.MM.y' }}
            </div>
        </div>
        <div class="row">
            <div class="col-4">
                <label class="form-label">{{ 'recap.label.slot' | translate }}</label>
            </div>
            <div class="col-8">
                {{ event.date | date:'HH:mm' }} - {{ event.endDate | date:'HH:mm' }}
            </div>
        </div>
        <div class="row">
            <div class="col-4">
                <label class="form-label">{{ 'recap.label.childName' | translate }}</label>
            </div>
            <div class="col-8">
                {{ event.child?.name }}
            </div>
        </div>
        <div class="row">
            <div class="col-4">
                <label class="form-label">{{ 'recap.label.birthday' | translate }}</label>
            </div>
            <div class="col-8">
                {{ event.child?.age }} {{ 'recap.label.years' | translate }}
            </div>
        </div>
        <div class="row">
            <div class="col-4">
                <label class="form-label">{{ 'recap.label.gender' | translate }}</label>
            </div>
            <div class="col-8">
                {{ 'global.childGender.' + event.child?.gender | translate }}
            </div>
        </div>
        <div class="row">
            <div class="col-4">
                <label class="form-label">{{ 'recap.label.invitedKids' | translate }}</label>
            </div>
            <div class="col-8">
                {{ event.childrenCount }}
            </div>
        </div>
        <div class="row">
            <div class="col-4">
                <label class="form-label">{{ 'recap.label.cake' | translate }}</label>
            </div>
            <div class="col-8">
                {{ 'recap.label.chocolate' | translate }}
            </div>
        </div>
        <div class="row">
            <div class="col-4">
                <label class="form-label">{{ 'recap.label.theme' | translate }}</label>
            </div>
            <div class="col-8">
                {{ 'global.theme.' + event.theme | translate }}
            </div>
        </div>
    </div>
    <div class="col-md-6">
        <div class="row">
            <div class="col-4">
                <label class="form-label">{{ 'recap.label.invitationCards' | translate }}</label>
            </div>
            <div class="col-8">
                {{ (event.cards.requested ? 'global.yes' : 'global.no') | translate}}
            </div>
        </div>
        <div class="row">
            <div class="col-4">
                <label class="form-label">{{ 'recap.label.price' | translate }}</label>
            </div>
            <div class="col-8 text-danger">
                CHF {{ computePrice() }} *
            </div>
        </div>
        <div class="row">
            <div class="col-4">
                <label class="form-label">{{ 'recap.label.language' | translate }}</label>
            </div>
            <div class="col-8">
                {{ 'global.language.' + event.language | translate }}
            </div>
        </div>
        <div class="row">
            <div class="col-4">
                <label class="form-label">{{ 'recap.label.contact.name' | translate }}</label>
            </div>
            <div class="col-8">
                {{ event.contact?.firstName + ' ' + event.contact?.lastName }}
            </div>
        </div>
        <div class="row">
            <div class="col-4">
                <label class="form-label">{{ 'recap.label.contact.phone' | translate }}</label>
            </div>
            <div class="col-8">
                {{ event.contact?.phoneNumber }}
            </div>
        </div>

        @if(event.contact?.address) {
            <div class="row">
                <div class="col-4">
                    <label class="form-label">{{ 'recap.label.contact.address' | translate }}</label>
                </div>
                <div class="col-8">
                    {{ event.contact?.address }}
                </div>
            </div>
        }

        <div class="row">
            <div class="col-4">
                <label class="form-label">{{ 'recap.label.contact.email' | translate }}</label>
            </div>
            <div class="col-8">
                {{ event.contact?.email }}
            </div>
        </div>
    </div>
</div>

* {{ "recap.label.priceDisclaimer" | translate }}